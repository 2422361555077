<template>
  <div>
    <button class="btn btn-outline-info" @click="revenir">
      Revenir à la liste
    </button>

    <b-form @submit="onSubmit">
      <b-form-group label="Titre">
        <b-form-input
          :state="titre.length > 0"
          type="text"
          placeholder="Titre"
          v-model="titre"
          id="Titre"
        />
      </b-form-group>

      <b-form-group label="Nom">
        <b-form-input
          :state="nom.length > 0"
          type="text"
          placeholder="Nom"
          v-model="nom"
          id="nom"
        />
      </b-form-group>

      <b-form-group label="Ouvert?">
        <b-form-checkbox v-model="is_open" id="is_open" />
      </b-form-group>

      <b-form-group label="Description">
        <vue-editor id="description" v-model="description" />
      </b-form-group>

      <b-form-group label="Tutorial">
        <vue-editor id="tutorial" v-model="tutorial" />
      </b-form-group>

      <b-form-group label="Charte">
        <vue-editor id="charte" v-model="charte" />
      </b-form-group>

      <b-form-group label="Bandeau">
        <b-form-file accept="image/*" v-model="bandeau" />

        <img
          v-if="id"
          style="max-height: 50px; max-width: 50px"
          :src="id + '/download/bandeau'"
          alt=""
        />

        <a v-if="id" :href="id + '/download/bandeau'">
          <span>Voir bandeau</span></a
        >
      </b-form-group>

      <p class="text-warning" v-if="!isValid()">
        Merci de compléter tout le formulaire.
      </p>

      <b-button :disabled="!isValid()" type="submit" variant="primary">
        <span v-if="id"><b>Modifier</b></span>
        <span v-else><b>Ajouter</b></span>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  props: {
    selectedTheme: Object,
  },

  components: {
    VueEditor,
  },

  data() {
    return {
      id: "",
      nom: "",
      titre: "",
      description: "",
      tutorial: "",
      charte: "",
      is_open: false,
      bandeau: null,
    };
  },

  created() {
    if (this.selectedTheme && Object.keys(this.selectedTheme).length > 0) {
      this.id = this.selectedTheme.id;
      this.nom = this.selectedTheme.nom;
      this.titre = this.selectedTheme.titre;
      this.description = this.selectedTheme.description;
      this.tutorial = this.selectedTheme.tutorial;
      this.charte = this.selectedTheme.charte;
      this.is_open = this.selectedTheme.is_open || false;
    }
    this.$emit("selectedTheme", {});
  },

  methods: {
    isValid() {
      return this.titre.length > 0 && this.nom.length > 0;
    },

    revenir() {
      this.$router.push("/admin/themes/");
    },

    onSubmit(evt) {
      evt.preventDefault();
      const formData = new FormData();
      formData.append("nom", this.nom);
      formData.append("titre", this.titre);
      formData.append("is_open", this.is_open);
      formData.append("description", this.description);
      formData.append("tutorial", this.tutorial);
      formData.append("charte", this.charte);
      // formData.append("bandeau", this.$refs.bandeau.files[0]);
      formData.append("bandeau", this.bandeau);
      if (this.id) {
        formData.append("id", this.id);
      }
      axios
        .post("/admin/api/add_or_update_theme", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$notify({
            group: "user",
            title: "Thème ajouté ou modifié avec succès",
            type: "success",
          });
          this.$router.push("/admin/themes/");
        })
        .catch(() => {
          this.$notify({
            group: "user",
            title:
              "Ooops, une erreur est survenue, veuillez réessayer ultérieurement",
            type: "error",
          });
        });
    },
  },
};
</script>
