<template>
  <div>
    <div>
      <b-button
        id="ddown-header"
        class="m-2"
        v-if="NonViewedAlerts.length == 0"
        variant="outline-info"
        ><i class="far fa-bell"></i> 0</b-button
      >

      <!--     :text="NonViewedAlerts.length + ''"-->
      <b-dropdown v-else id="ddown-header" variant="outline-info" class="m-2">
        <template slot="button-content"
          ><i class="far fa-bell"> &nbsp;</i
          >{{ NonViewedAlerts.length }}</template
        >

        <b-dropdown-header>Nouveaux AMI à consulter </b-dropdown-header>
        <b-dropdown-item-button
          :key="'alert' + alert.ami_id"
          @click="gotoAlert(alert.ami_id)"
          v-for="alert in NonViewedAlerts"
          >{{ alert.ami_name }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>

    <!--<span class="badge badge-info badge-pill">{{-->
    <!--NonViewedAlerts.length-->
    <!--}}</span>-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},

  data: function () {
    return {
      NonViewedAlerts: [],
    };
  },

  methods: {
    gotoAlert(id) {
      this.$router.push({ name: "ami_info", params: { id } });
      location.reload();
    },

    getAlertsCount() {
      axios.get("/api/NotViewAlerts").then((response) => {
        this.NonViewedAlerts = response.data;
      });
    },
  },

  mounted() {
    this.getAlertsCount();
    // this.$nextTick(function() {
    //   window.setInterval(() => {
    //     this.getAlertsCount();
    //   }, 10000);
    // });
  },
};
</script>
