import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/amis/:id",
      name: "ami_info",
    },
    {
      path: "/application_success",
      name: "application_success",
    },
    {
      path: "/applications",
      name: "mes_applications",
    },
    {
      path: "/faq",
      name: "faq",
    },
    // Admin
    {
      path: "/admin/pages/",
      name: "pages",
    },
    {
      path: "/admin/posts/",
      name: "posts",
    },
  ],
});
