// Polyfils
import "babel-polyfill";
import "filepond-polyfill";

import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Notifications from "vue-notification";
import vDialogs from "v-dialogs";

import router from "./router";

// Admin components
import Alerts from "./components/Alerts";
import AmiResponse from "./components/AmiResponse/BaseAmiResponse";
import BaseThemes from "./components/Themes/BaseThemes";
import BaseAmis from "./components/Amis/BaseAmis";
import BaseUsers from "./components/Users/BaseUsers";
import BaseFaqs from "./components/Faq/BaseFaq";
import ButtonDeletePJ from "./components/ButtonDeletePJ";
import EditPage from "./components/Pages/EditPage";

import UserList from "./components/Users/UserList";
import ApplicationList from "./admin/ApplicationList";
import PostList from "./admin/PostList";
import PostEdit from "./admin/PostEdit";

// Front components
import VueAgile from "vue-agile";

import AmiList from "./front/AmiList";
import FaqList from "./front/FaqList";
import FaqNew from "./front/FaqNew";

// CSS
import "./style/custom.css";
import "./style/agile.css";

// Ant Design for backoffice (currently used by the Application list)
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);

Vue.use(vDialogs);
Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.config.productionTip = false;

const mountPoints = [
  // Admin
  ["alerts", Alerts],
  ["CRUDThemes", BaseThemes],
  ["CRUDAmis", BaseAmis],
  ["CRUDUsers", BaseUsers],
  ["CRUDFaq", BaseFaqs],
  ["EditPage", EditPage],
  ["button-delete-js", ButtonDeletePJ],

  // Front
  ["ami-response", AmiResponse],
  ["faq-list", FaqList],
  ["new-faq", FaqNew],
  ["ami-list", AmiList],
];

for (const pair of mountPoints) {
  const id = pair[0];
  const component = pair[1];
  if (document.getElementById(id)) {
    new Vue({
      router,
      render: (h) => h(component),
    }).$mount(`#${id}`);
  }
}

let el;

el = document.getElementById("admin-app");
if (el) {
  const app = new Vue({
    el: "#admin-app",
    components: {
      UserList,
      ApplicationList,
      PostList,
      PostEdit,
    },
  });
  app.$mount(el);
}

el = document.getElementById("home-app");
if (el) {
  Vue.use(VueAgile);
  const app = new Vue({
    el: "#home-app",
    components: {
      VueAgile,
    },
  });
  app.$mount(el);
}
