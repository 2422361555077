<template>
  <div>
    <table style="width: 85%">
      <tr style="height: 70px">
        <td style="width: 45%"></td>
        <td style="width: 45%"></td>
        <td>
          <button
            @click="addUser"
            type="button"
            class="close"
            aria-label="Close"
          >
            <i style="font-size: 24px" class="fa">&#xf067;</i>
          </button>
        </td>
      </tr>

      <tr :key="'user' + index" v-for="(user, index) in users">
        <td>
          <div style="display: inline-flex; width: 100%">
            <button
              @click="detail(user)"
              type="button"
              class="close"
              aria-label="Close"
            >
              <span style="font-size: 18px; color: #0048a7"
                >{{ user.organisme }}
                <template v-if="user.fonction">({{ user.fonction }}) </template>
              </span>
            </button>
          </div>
        </td>
        <td>
          <div style="display: inline-flex; width: 100%">
            <button
              @click="detail(user)"
              type="button"
              class="close"
              aria-label="Close"
            >
              <span style="font-size: 18px; color: #0048a7"
                >{{ user.nom }}, {{ user.prenom }}</span
              >
            </button>
          </div>
        </td>

        <td>
          <button
            @click="modify(user)"
            type="button"
            class="close"
            aria-label="Close"
          >
            <i style="font-size: 24px" class="fa">&#xf044;</i>
          </button>
        </td>
        <td>
          <button
            @click="remove(user)"
            type="button"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      users: [],
    };
  },

  created() {
    axios.get("/admin/api/users/").then((response) => {
      this.users = response.data;
    });
  },

  methods: {
    addUser() {
      this.$router.push("/admin/users/add");
    },

    modify(user) {
      this.$emit("selectedUser", user);
      this.$router.push("/admin/users/modify");
    },

    detail(user) {
      this.$emit("selectedUser", user);
      this.$router.push("/admin/users/detail");
    },

    remove(user) {
      const vue = this;
      this.$dlg.alert(
        "Êtes-vous sûr de vouloir supprimer " + user.nom + " ?",
        function () {
          axios
            .post("/admin/api/delete_user", user)
            .then(() => {
              vue.$notify({
                group: "user",
                title: "Supprimé avec succès",
                type: "success",
              });
              const i = vue.users.map((item) => item.id).indexOf(user.id); // find index of your object
              vue.users.splice(i, 1); // remove it from array
            })
            .catch(() => {
              vue.$notify({
                group: "user",
                title: "Erreur de suppression",
                type: "error",
              });
            });
        },
        {
          messageType: "confirm",
          language: "en",
        }
      );
    },
  },
};
</script>
