<template>
  <div class="mt-4">
    <a-table
      :columns="columns"
      :dataSource="items"
      :rowKey="(record) => record.id"
    >
      <span slot="experts" slot-scope="record">
        <a-select
          mode="multiple"
          v-model="record.experts"
          style="width: 100%"
          @change="onExpertChange(record.id)"
          placeholder="Please select"
        >
          <a-select-option v-for="expert in experts" :key="expert.id"
            >{{ expert.name }}
          </a-select-option>
        </a-select>
      </span>
    </a-table>
  </div>
</template>

<script>
import fp from "lodash/fp";
import axios from "axios";

export default {
  data() {
    // eslint-disable-next-line no-undef
    const data = DATA;

    const columns = [
      {
        title: "AMI",
        dataIndex: "ami",
        filters: data.amis.map((ami) => ({ text: ami, value: ami })),
        onFilter: (value, record) => record.ami.indexOf(value) >= 0,
        sorter: true,
        width: "20%",
      },
      {
        title: "Organisme",
        dataIndex: "organisme",
        sorter: (a, b) => a.organisme.localeCompare(b.organisme),
        width: "20%",
      },
      {
        title: "Nom",
        dataIndex: "nom",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
        width: "20%",
      },

      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.date.localeCompare(b.date),
        width: "20%",
      },
      {
        title: "Experts",
        dataIndex: "",
        key: "y",
        scopedSlots: { customRender: "experts" },
        width: "20%",
      },
    ];
    return {
      columns: columns,
      items: data.applications,
      experts: data.experts,
    };
  },

  methods: {
    onExpertChange(record_id) {
      const record = fp.find((record) => record.id == record_id)(this.items);

      const params = {
        expert_ids: record.experts,
      };
      axios.post(`/admin/api/responses/${record.id}`, params);
    },
  },
};
</script>
