<template>
  <div>
    <create-modify-theme
      style="width: 88%"
      :selectedTheme="selectedTheme"
      @selectedTheme="selectedTheme = $event"
      v-if="
        $route.path == '/admin/themes/add' ||
        ($route.path == '/admin/themes/modify' && selectedTheme != {})
      "
      @themePage="themePage = $event"
    />

    <all-theme
      @selectedTheme="selectedTheme = $event"
      v-if="$route.path == '/admin/themes/'"
      @themePage="themePage = $event"
    />

    <detail
      :selectedTheme="selectedTheme"
      @selectedTheme="selectedTheme = $event"
      v-if="
        $route.path == '/admin/themes/detail' ||
        ($route.path == '/admin/themes/detail' && selectedTheme != {})
      "
      @themePage="themePage = $event"
    />

    <router-view />

    <notifications style="height: 60px" group="user" position="top center" />
  </div>
</template>

<script>
import CreateModifyTheme from "./CreateModifyTheme";
import AllTheme from "./AllTheme";
import detail from "./detail";

export default {
  data() {
    return {
      selectedTheme: {},
      themePage: "list",
    };
  },
  components: {
    CreateModifyTheme,
    AllTheme,
    detail,
  },
};
</script>
