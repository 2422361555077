<template>
  <div>
    <h3 v-if="mode === 'new'">Nouveau post</h3>
    <h3 v-else>Editer le post: {{ title }}</h3>

    <b-form fluid class="mt-4">
      <b-form-group label="Titre">
        <b-form-input v-model="title" />
      </b-form-group>

      <b-form-group label="Date">
        <b-form-datepicker v-model="date"></b-form-datepicker>
      </b-form-group>

      <b-form-group label="Contenu">
        <vue-editor v-model="body" />
      </b-form-group>

      <b-form-group label="Visuel">
        <b-form-file
          v-model="visuel"
          accept="image/*"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>

        <img
          v-if="id"
          style="max-height: 200px; max-width: 200px"
          :src="id + '/visuel'"
          alt=""
        />
      </b-form-group>
    </b-form>

    <div class="mt-4">
      <b-button @click="modifierPage" variant="primary" class="mr-4">
        <span v-if="mode === 'edit'">Modifier</span>
        <span v-else>Créer</span>
      </b-button>

      <b-button @click="annuler"> Annuler </b-button>
    </div>

    <notifications style="height: 60px" group="user" position="top center" />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  props: {
    selectedTheme: Object,
  },

  components: {
    VueEditor,
  },

  data() {
    if (window.mode == "new") {
      return {
        mode: "new",
        id: "",
        title: "",
        date: "",
        body: "",
        visuel: null,
      };
    } else {
      const object = window.object;
      return {
        mode: "edit",
        id: object.id,
        title: object.title,
        date: object.date,
        body: object.body,
        visuel: null,
      };
    }
  },

  methods: {
    annuler() {
      location.assign("/admin/posts/");
    },

    modifierPage() {
      const vue = this;

      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("body", this.body);
      formData.append("date", this.date);
      formData.append("visuel", this.visuel);
      if (this.id) {
        formData.append("id", this.id);
      }

      // const data = {
      //   id: this.id,
      //   body: this.body,
      //   title: this.title,
      //   date: this.date,
      // };

      let url, msg;
      if (this.mode === "edit") {
        url = `/admin/api/posts/${this.id}`;
        msg = "Modifié avec succès";
      } else {
        url = "/admin/api/posts/";
        msg = "Créé avec succès";
      }

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          vue.$notify({
            group: "user",
            title: msg,
            type: "success",
          });
          location.assign("/admin/posts/");
        })
        .catch(function () {
          vue.$notify({
            group: "user",
            title:
              "Ooops, une erreur est survenue, veuillez réessayer ulterieurement",
            type: "error",
          });
        });
    },
  },
};
</script>
