<template>
  <div>
    <table class="w-100">
      <tr>
        <td class="w-50">
          <b-dropdown
            variant="primary"
            :text="ThemeFilterText"
            class="m-2 w-100 mr-5"
          >
            <b-dropdown-item @click="filter_by_theme(null)"
              ><b>Filtrer par programme</b></b-dropdown-item
            >
            <b-dropdown-item
              v-for="(theme, index) in availableThemes"
              :key="'theme' + index"
              @click="filter_by_theme(theme)"
              >{{ theme.titre }}
            </b-dropdown-item>
          </b-dropdown>
        </td>
        <td class="w-50">
          <b-dropdown
            variant="primary"
            :text="AmiFilterText"
            class="m-2 w-100 ml-5"
          >
            <b-dropdown-item @click="filter_by_ami(null)"
              ><b>Filtrer par AMI</b></b-dropdown-item
            >
            <b-dropdown-item
              v-for="(ami, index) in availableAmis"
              :key="'ami' + index"
              @click="filter_by_ami(ami)"
              >{{ ami.titre }}
            </b-dropdown-item>
          </b-dropdown>
        </td>
      </tr>
    </table>

    <div v-for="(faq, index) in faqs" :key="'faq' + index">
      <div v-if="faq.published" class="card mb-3">
        <div class="card-header" :id="'heading' + index">
          <button
            style="width: 100%; font-size: 1em"
            class="btn btn-link text-left"
            type="button"
            data-toggle="collapse"
            :data-target="'#collapse' + index"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <span>{{ index + 1 }} - </span>
            <span v-if="faq.theme_name" style="margin: 0 1%; font-size: 80%">
              {{ faq.theme_name }} /
              <span v-if="faq.ami_name"> {{ faq.ami_name }} / </span>
              <span v-else> - / </span>
            </span>
            <span style="font-size: 1em">
              {{ faq.question }}
            </span>
          </button>
        </div>

        <div
          :id="'collapse' + index"
          class="collapse"
          :aria-labelledby="'heading' + index"
          data-parent="#accordionExample"
        >
          <div class="card-body" v-html="faq.response"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import fp from "lodash/fp";

export default {
  data() {
    return {
      ThemeFilterText: "Filtrer par Programme",
      AmiFilterText: "Filtrer par AMI",
      faqs: [],
      all_faqs: [], // for filter
      availableAmis: [],
      availableThemes: [],
      selectedTheme: {},
    };
  },

  created() {
    axios.get("/api/themes/").then((response) => {
      this.availableThemes = fp.filter((x) => x.is_open)(response.data);
    });

    axios.get("/api/amis/").then((response) => {
      this.availableAmis = response.data;
    });

    const params = {
      theme_id: this.$route.query.theme,
      ami_id: this.$route.query.ami,
    };
    axios.get("/api/faq_list", { params }).then((response) => {
      this.all_faqs = response.data;
      this.faqs = response.data;
    });
  },

  watch: {
    selectedTheme(theme) {
      axios.get("/api/" + theme.id + "/amis").then((response) => {
        this.availableAmis = response.data;
      });
    },
  },

  methods: {
    filter_by_ami(ami) {
      if (ami) {
        this.AmiFilterText = ami.name;
        this.faqs = this.all_faqs.filter((faq) => faq.ami_id == ami.id);
      } else if (this.ThemeFilterText == "Filtrer par Programme") {
        this.AmiFilterText = "Filtrer par AMI";
        this.faqs = this.all_faqs;
      } else {
        this.AmiFilterText = "Filtrer par AMI";
        this.faqs = this.all_faqs.filter(
          (faq) => faq.theme_id == this.selectedTheme.id
        );
      }
    },

    filter_by_theme(theme) {
      if (theme) {
        this.selectedTheme = theme;
        this.ThemeFilterText = theme.nom;
        this.faqs = this.all_faqs.filter((faq) => faq.theme_id == theme.id);
      } else {
        this.ThemeFilterText = "Filtrer par Programme";
        this.faqs = this.all_faqs;
      }
    },
  },
};
</script>
