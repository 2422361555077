<template>
  <div>
    <b-table
      bordered
      hover
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
    />
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    />
  </div>
</template>

<script>
// import axios from "axios";

// const columns = [
//   {
//     title: 'Id',
//     dataIndex: 'id',
//     sorter: true,
//     width: '6%',
//   },
//   {
//     title: 'Nom',
//     dataIndex: 'nom',
//     sorter: true,
//     width: '22%',
//   },
//   {
//     title: 'Prénom',
//     dataIndex: 'prenom',
//     sorter: true,
//     width: '22%',
//   },
//   {
//     title: 'Organisme',
//     dataIndex: 'organisme',
//     sorter: true,
//     width: '40%',
//   },
// ];

export default {
  data() {
    return {
      // eslint-disable-next-line no-undef
      items: DATA.users,
      fields: ["id", "nom", "prenom", "organisme"],
      perPage: 50,
      currentPage: 1,
    };
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },

  methods: {
    detail(user) {
      this.$emit("selectedUser", user);
      this.$router.push("/admin/users/detail");
    },
  },

  // methods: {
  //   addUser() {
  //     this.$router.push("/admin/users/add");
  //   },
  //
  //   modify(user) {
  //     this.$emit("selectedUser", user);
  //     this.$router.push("/admin/users/modify");
  //   },
  //
  //   detail(user) {
  //     this.$emit("selectedUser", user);
  //     this.$router.push("/admin/users/detail");
  //   },
  //
  //   remove(user) {
  //     const vue = this;
  //     this.$dlg.alert(
  //       "Êtes-vous sûr de vouloir supprimer " + user.nom + " ?",
  //       function() {
  //         axios
  //           .post("/admin_api/delete_user", user)
  //           .then(() => {
  //             vue.$notify({
  //               group: "user",
  //               title: "Supprimé avec succès",
  //               type: "success",
  //             });
  //             const i = vue.users.map(item => item.id).indexOf(user.id); // find index of your object
  //             vue.users.splice(i, 1); // remove it from array
  //           })
  //           .catch(() => {
  //             vue.$notify({
  //               group: "user",
  //               title: "Erreur de suppression",
  //               type: "error",
  //             });
  //           });
  //       },
  //       {
  //         messageType: "confirm",
  //         language: "en",
  //       }
  //     );
  //   },
  // },
  //
  // created() {
  //   axios.get("/admin_api/all_users").then(response => {
  //     this.users = response.data;
  //   });
  // },
};
</script>
