<template>
  <div>
    <notifications style="height: 60px" group="user" position="top center" />

    <all-faq
      @selectedFaq="selectedFaq = $event"
      v-if="$route.path == '/admin/faqs/'"
      @faqPage="faqPage = $event"
    ></all-faq>

    <create-modify-faq
      style="width: 88%"
      :selectedFaq="selectedFaq"
      @selectedFaq="selectedFaq = $event"
      v-if="
        $route.path == '/admin/faqs/add' ||
        ($route.path == '/admin/faqs/modify' && selectedFaq != {})
      "
      @faqPage="faqPage = $event"
    ></create-modify-faq>

    <detail
      :selectedFaq="selectedFaq"
      @selectedFaq="selectedFaq = $event"
      v-if="
        $route.path == '/admin/faqs/detail' ||
        ($route.path == '/admin/faqs/detail' && selectedFaq != {})
      "
      @faqPage="faqPage = $event"
    ></detail>
  </div>
</template>

<script>
import CreateModifyFaq from "./CreateModifyFaq";
import AllFaq from "./AllFaq";
import detail from "./detail";

export default {
  data() {
    return {
      selectedFaq: {},
      faqPage: "list",
    };
  },
  components: {
    CreateModifyFaq,
    AllFaq,
    detail,
  },
};
</script>
