<template>
  <div style="width: 87%">
    <button class="btn btn-outline-info" @click="revenir">
      Revenir à la liste
    </button>

    <h1 style="margin: 2% 20%; width: 80%">{{ selectedAmi.titre }}</h1>

    <div class="form-group row">
      <label for="name" class="col-sm-2 col-form-label"><b>Nom: </b></label>
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="name"
          :value="selectedAmi.name"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="open_date" class="col-sm-2 col-form-label"
        ><b>Date ouverture: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="open_date"
          :value="selectedAmi.open_date"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="close_date" class="col-sm-2 col-form-label"
        ><b>Date cloture: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="close_date"
          :value="selectedAmi.close_date"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="link" class="col-sm-2 col-form-label"><b>Lien: </b></label>
      <div style="padding-left: 8%" class="col-sm-8">
        <a :href="selectedAmi.link"> {{ selectedAmi.link }}</a>
      </div>
    </div>

    <div class="form-group row">
      <label for="theme_name" class="col-sm-2 col-form-label"
        ><b>Thématique: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="theme_name"
          :value="selectedAmi.theme"
        />
      </div>
    </div>

    <div class="form-group">
      <label for="resume" class="col-form-label"><b>Résumé: </b></label>
      <div
        style="background-color: rgba(216, 216, 226, 0.27); padding: 5px"
        id="resume"
        v-html="selectedAmi.resume"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedAmi: Object,
  },

  methods: {
    revenir() {
      this.$router.push("/admin/amis/");
    },
  },

  beforeDestroy() {
    this.$emit("selectedAmi", {});
  },
};
</script>
