<template>
  <div>
    <table style="width: 85%">
      <tr style="height: 70px">
        <td style="width: 90%"></td>
        <td>
          <button
            @click="addTheme"
            type="button"
            class="close"
            aria-label="Nouveau programme"
          >
            <i style="font-size: 24px" class="fa">&#xf067;</i>
          </button>
        </td>
      </tr>

      <tr :key="'theme' + index" v-for="(theme, index) in themes">
        <td style="width: 90%">
          <div style="display: inline-flex; align-items: center; width: 100%">
            <button
              @click="detail(theme)"
              type="button"
              class="close"
              aria-label="Close"
            >
              {{ theme.nom }}
            </button>
          </div>
        </td>
        <td>
          <button
            @click="modify(theme)"
            type="button"
            class="close"
            aria-label="Close"
          >
            <i style="font-size: 24px" class="fa">&#xf044;</i>
          </button>
        </td>
        <td>
          <button
            @click="remove(theme)"
            type="button"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AllTheme",

  data() {
    return {
      themes: [],
    };
  },

  methods: {
    addTheme() {
      this.$router.push("/admin/themes/add");
    },

    modify(theme) {
      this.$emit("selectedTheme", theme);
      this.$router.push("/admin/themes/modify");
    },

    detail(theme) {
      this.$emit("selectedTheme", theme);
      this.$router.push("/admin/themes/detail");
    },

    remove(theme) {
      const vue = this;
      this.$dlg.alert(
        "Êtes-vous sûr de vouloir supprimer " + theme.nom + " ?",
        function () {
          axios.post("/admin/api/delete_theme", theme).then((response) => {
            if (response.data.success == true) {
              vue.$notify({
                group: "user",
                title: "Supprimé avec succès",
                type: "success",
              });
              const i = vue.themes.map((item) => item.id).indexOf(theme.id); // find index of your object
              vue.themes.splice(i, 1); // remove it from array
            } else {
              vue.$notify({
                group: "user",
                title: "Erreur de suppression",
                type: "error",
              });
            }
          });
          // .catch(function() {
          //   vue.$notify({
          //     group: "user",
          //     title:
          //       "Ooops, une erreur est survenue, veuillez réessayer ulterieurement",
          //     type: "error",
          //   });
          // });
        },
        {
          messageType: "confirm",
          language: "en",
        }
      );
    },
  },
  created() {
    axios.get("/api/themes/").then((response) => {
      this.themes = response.data;
    });
  },
};
</script>
