<template>
  <div>
    <div style="margin: 2% 0% 0% 1%; width: 85%">
      <b-container fluid>
        <b-row sm="3">
          <label for="nom">Nom:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            class="form-control-plaintext"
            type="text"
            readonly
            v-model="nom"
            id="nom"
          />
        </b-row>

        <br />

        <b-row sm="3">
          <label for="contenu">Contenu:</label>
        </b-row>
        <b-row sm="9">
          <vue-editor id="contenu" v-model="contenu" />
        </b-row>

        <br /><br /><br />
      </b-container>

      <table style="width: 40%">
        <tr>
          <td>
            <b-button @click="annuler">
              <b>Annuler</b>
            </b-button>
          </td>
          <td style="width: 25%">
            <b-button
              @click="modifierPage"
              style="margin: 1% 0% 4% 35%"
              variant="primary"
            >
              <span><b>Modifier</b></span>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <notifications style="height: 60px" group="user" position="top center" />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  props: {
    selectedTheme: Object,
  },

  components: {
    VueEditor,
  },

  data() {
    return {
      id: "",
      nom: "",
      contenu: "",
    };
  },

  created() {
    const id = this.$route.path.split("/")[3];
    axios.get(`/admin/api/pages/${id}`).then((response) => {
      this.id = response.data.id;
      this.nom = response.data.nom;
      this.contenu = response.data.contenu;
    });
  },

  methods: {
    annuler() {
      this.$router.push({ name: "pages" });
      location.reload();
    },

    modifierPage() {
      const vue = this;
      const response = {
        contenu: this.contenu,
      };
      axios
        .post(`/admin/api/pages/${this.id}`, response)
        .then(() => {
          vue.$notify({
            group: "user",
            title: "Modifié avec succès",
            type: "success",
          });
          this.$router.push({ name: "pages" });
          location.reload();
        })
        .catch(function () {
          vue.$notify({
            group: "user",
            title:
              "Ooops, une erreur est survenue, veuillez réessayer ulterieurement",
            type: "error",
          });
        });
    },
  },
};
</script>
