<template>
  <div>
    <all-user
      @selectedUser="selectedUser = $event"
      v-if="$route.path == '/admin/users/'"
      @userPage="userPage = $event"
    ></all-user>
    <create-modify-user
      style="width: 88%"
      :selectedUser="selectedUser"
      @selectedUser="selectedUser = $event"
      v-if="
        $route.path == '/admin/users/add' ||
        ($route.path == '/admin/users/modify' && selectedUser != {})
      "
      @userPage="userPage = $event"
    ></create-modify-user>
    <detail
      :selectedUser="selectedUser"
      @selectedUser="selectedUser = $event"
      v-if="
        $route.path == '/admin/users/detail' ||
        ($route.path == '/admin/users/detail' && selectedUser != {})
      "
      @userPage="userPage = $event"
    ></detail>
    <notifications group="user" style="height: 60px" position="top right" />
  </div>
</template>

<script>
import CreateModifyUser from "./CreateModifyUser";
import AllUser from "./AllUsers";
import detail from "./detail";

export default {
  data() {
    return {
      selectedUser: {},
      userPage: "list",
    };
  },

  components: {
    CreateModifyUser,
    AllUser,
    detail,
  },
};
</script>
