<template>
  <div class="ui text container">
    <h1>Tous les AMI en cours</h1>

    <p v-if="!programmes.length">Aucun AMI en cours.</p>

    <template v-else>
      <p class="text-center">
        <b-dropdown :text="text" variant="primary" class="m-md-2">
          <b-dropdown-item @click="filter()">Tous</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            v-for="(programme, index) in programmes"
            :key="index"
            @click="filter(programme)"
            >{{ programme }}
          </b-dropdown-item>
        </b-dropdown>
      </p>

      <div v-for="ami in filtered_amis" :key="ami.id" class="card">
        <div class="card-header" :id="`heading${ami.title}`">
          <h5 class="mb-0">
            <a href="#" @click="toggle(ami.id)">{{ ami.title }}</a>
          </h5>

          <small v-if="ami.days_left === 0" class="text-muted"
            >Se termine aujourd'hui</small
          >
          <small v-else-if="ami.days_left === 1" class="text-muted"
            >Se termine demain</small
          >
          <small v-else class="text-muted"
            >Se termine dans {{ ami.days_left }} jours</small
          >
        </div>

        <div v-if="ami.id === open_ami_id" class="card-body">
          <div v-html="ami.resume"></div>

          <div>
            <a :href="`/amis/${ami.id}`" class="btn btn-primary"
              >Plus de détails</a
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import fp from "lodash/fp";

export default {
  data() {
    // eslint-disable-next-line no-undef
    const data = _.clone(DATA);
    data.selected = "";
    data.filtered_amis = data.amis;
    data.text = "Programme";
    data.open_ami_id = null;
    return data;
  },

  methods: {
    filter(programme) {
      if (!programme) {
        this.filtered_amis = this.amis;
        this.text = "Programme";
      } else {
        this.text = programme;
        this.filtered_amis = fp.filter(
          (ami) => ami.programme === programme,
          this.amis
        );
      }
    },

    toggle(ami_id) {
      if (!this.open_ami_id) {
        this.open_ami_id = ami_id;
      } else {
        this.open_ami_id = null;
      }
    },
  },
};
</script>
