<template>
  <div>
    <button class="btn btn-outline-info" @click="revenir">
      Revenir à la liste
    </button>

    <div style="margin-top: 2%">
      <b-container fluid>
        <b-row sm="3">
          <label for="nom">Nom:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="nom.length > 0"
            type="text"
            placeholder="Nom"
            v-model="nom"
            id="nom"
          />
        </b-row>

        <b-row sm="3">
          <label for="prenom">Prenom:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="prenom.length > 0"
            type="text"
            placeholder="Prenom"
            v-model="prenom"
            id="prenom"
          />
        </b-row>

        <br />

        <b-row sm="3">
          <label for="organisme">Organisme:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="organisme.length > 0"
            type="text"
            placeholder="Organisme"
            v-model="organisme"
            id="organisme"
          />
        </b-row>

        <b-row sm="3">
          <label for="siret">Siret:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="(siret || '').length > 0"
            type="text"
            placeholder="Siret"
            v-model="siret"
            id="siret"
          />
        </b-row>

        <b-row sm="3">
          <label for="telephone">Telephone:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="(telephone || '').length > 0"
            type="text"
            placeholder="Telephone"
            v-model="telephone"
            id="telephone"
          />
        </b-row>

        <b-row sm="3">
          <label for="rue">Rue:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="(rue || '').length > 0"
            type="text"
            placeholder="Rue"
            v-model="rue"
            id="rue"
          />
        </b-row>

        <b-row sm="3">
          <label for="ville">Ville:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="(ville || '').length > 0"
            type="text"
            placeholder="ville"
            v-model="ville"
            id="ville"
          />
        </b-row>

        <b-row sm="3">
          <label for="code_postal">Code postal:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="code_postal.length > 0"
            type="text"
            placeholder="Code postal"
            v-model="code_postal"
            id="code_postal"
          />
        </b-row>

        <b-row sm="3">
          <label for="fonction">Fonction:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="(fonction || '').length > 0"
            type="text"
            placeholder="Fonction"
            v-model="fonction"
            id="fonction"
          />
        </b-row>

        <b-row sm="3">
          <label for="email">Email:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            @input="checkValidity"
            :state="validMail"
            type="text"
            placeholder="Email"
            v-model="email"
            id="email"
            aria-describedby="inputLiveFeedback"
          />
          <b-form-invalid-feedback id="inputLiveFeedback">
            {{ mailErrorMsg }}
          </b-form-invalid-feedback>
        </b-row>
      </b-container>

      <b-button
        @click="ajouterModifierUser"
        style="width: 25%; margin: 1% 0% 4% 35%"
        variant="primary"
      >
        <span v-if="id"><b>Modifier</b></span>
        <span v-else><b>Ajouter</b></span>
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    selectedUser: Object,
  },

  data() {
    return {
      mode: "adding",
      id: "",
      nom: "",
      prenom: "",
      organisme: "",
      siret: "",
      telephone: "",
      rue: "",
      ville: "",
      code_postal: "",
      fonction: "",
      email: "",
      validMail: false,
      mailErrorMsg: "",
    };
  },

  created() {
    const adding = this.$route.path.indexOf("/add") > 0;
    this.mode = adding ? "adding" : "editing";
    const user = this.selectedUser;
    if (user && Object.keys(user).length > 0) {
      this.id = user.id;
      this.nom = user.nom;
      this.prenom = user.prenom;
      this.organisme = user.organisme;
      this.siret = user.siret;
      this.telephone = user.telephone;
      this.rue = user.rue;
      this.ville = user.ville;
      this.code_postal = user.code_postal;
      this.fonction = user.fonction;
      this.email = user.email;
    }
    this.mailErrorMsg = "";
    this.$emit("selectedUser", {});
  },

  methods: {
    revenir() {
      this.$router.push("/admin/users/");
    },

    checkValidity(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

      this.validMail = true;

      if (!re.test(String(email).toLowerCase())) {
        return;
      }

      if (this.mode == "editing") {
        this.validMail = true;
        return;
      }

      axios.get("/api/exist_mail/" + email).then((response) => {
        this.validMail = response.data;
        if (this.validMail) {
          this.mailErrorMsg = "";
        } else {
          this.mailErrorMsg = "Cette adresse mail est déja utilisée";
        }
      });
    },

    ajouterModifierUser() {
      if (
        this.nom &&
        this.prenom &&
        this.organisme &&
        this.siret &&
        this.telephone &&
        this.rue &&
        this.ville &&
        this.code_postal &&
        this.fonction &&
        this.email
      ) {
        const response = {
          nom: this.nom,
          prenom: this.prenom,
          organisme: this.organisme,
          siret: this.siret,
          telephone: this.telephone,
          rue: this.rue,
          ville: this.ville,
          code_postal: this.code_postal,
          fonction: this.fonction,
          email: this.email,
        };
        if (this.id) {
          response.id = this.id;
        }
        const vue = this;
        axios
          .post("/admin/api/users/", response)
          .then(() => {
            vue.$notify({
              group: "user",
              title: "Ajouté avec succès",
              type: "success",
            });
            this.$router.push("/admin/users/");
          })
          .catch(() => {
            vue.$notify({
              group: "user",
              title:
                "Ooops, une erreur est survenue, veuillez réessayer ulterieurement",
              type: "error",
            });
          });
      }
    },
  },
};
</script>
