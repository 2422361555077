<template>
  <div>
    <table style="width: 85%">
      <tr style="height: 70px">
        <td style="width: 90%"></td>
        <td>
          <button
            @click="addAmi"
            type="button"
            class="close"
            aria-label="Close"
          >
            <i style="font-size: 24px" class="fa">&#xf067;</i>
          </button>
        </td>
      </tr>

      <tr :key="'ami' + index" v-for="(ami, index) in amis">
        <td style="width: 90%">
          <!--<a :href="'/admin/amis/' + ami.id">{{ ami.name }}</a>-->
          <div style="display: inline-flex; align-items: center; width: 100%">
            <button
              @click="detail(ami)"
              type="button"
              class="close"
              aria-label="Close"
            >
              {{ ami.name }}
            </button>
          </div>
        </td>

        <td>
          <button
            @click="modify(ami)"
            type="button"
            class="close"
            aria-label="Close"
          >
            <i style="font-size: 24px" class="fa">&#xf044;</i>
          </button>
        </td>

        <td>
          <button
            @click="del(ami)"
            type="button"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AllAmi",

  data() {
    return {
      amis: [],
    };
  },

  created() {
    axios.get("/api/amis/").then((response) => {
      this.amis = response.data;
    });
  },

  methods: {
    addAmi() {
      this.$router.push("/admin/amis/add");
    },

    modify(ami) {
      this.$emit("selectedAmi", ami);
      this.$router.push("/admin/amis/modify");
    },

    detail(ami) {
      this.$emit("selectedAmi", ami);
      this.$router.push("/admin/amis/detail");
    },

    del(ami) {
      const vue = this;
      this.$dlg.alert(
        "Êtes-vous sûr de vouloir supprimer " + ami.titre + " ?",
        function () {
          axios
            .delete(`/admin/api/amis/${ami.id}`)
            .then(() => {
              vue.$notify({
                group: "user",
                title: "Supprimé avec succès",
                type: "success",
              });
              const i = vue.amis.map((item) => item.id).indexOf(ami.id); // find index of your object
              vue.amis.splice(i, 1); // remove it from array
            })
            .catch(() => {
              vue.$notify({
                group: "user",
                title: "Erreur de suppression",
                type: "error",
              });
            });
        },
        {
          messageType: "confirm",
          language: "en",
        }
      );
    },
  },
};
</script>
