<template>
  <div>
    <h1>Foire aux questions (FAQ)</h1>

    <h2 class="mt-5">Posez votre question</h2>

    <div style="color: #006fc9" class="mt-3 mb-2">
      <i><b>Thématique / Programme</b></i>
    </div>
    <select
      id="theme"
      name="theme"
      v-model="selectedTheme"
      class="custom-select"
    >
      <option selected disabled="disabled">Thématique</option>
      <option
        v-for="(theme, index) in availableThemes"
        :key="'theme' + index"
        :value="theme"
      >
        {{ theme.titre }}
      </option>
    </select>

    <div style="color: #006fc9" class="mt-4 mb-2">
      <i><b>AMI (optionel)</b></i>
    </div>

    <select v-model="selectedAmi" id="ami" name="ami" class="custom-select">
      <option selected></option>
      <option
        v-for="(ami, index) in availableAmis"
        :key="'ami' + index"
        :value="ami.titre"
      >
        {{ ami.titre }}
      </option>
    </select>

    <div style="color: #006fc9" class="mt-4 mb-2">
      <i><b>Question</b></i>
    </div>
    <div class="input-group">
      <textarea
        class="form-control"
        rows="20"
        id="question"
        name="question"
        v-model="question"
        aria-label="With textarea"
      ></textarea>
    </div>

    <div style="margin-left: 40%" class="mt-4">
      <button class="btn btn-info" @click="annuler">Annuler</button>
      &nbsp;
      <button
        :disabled="enableSoumission"
        class="btn btn-success"
        @click="AddFaq"
      >
        Soumettre
      </button>
    </div>

    <notifications style="height: 60px" group="user" position="top center" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FaqList",

  data() {
    return {
      selectedTheme: {},
      selectedAmi: "",
      availableAmis: [],
      availableThemes: [],
      question: "",
    };
  },

  computed: {
    enableSoumission() {
      return this.selectedTheme == {} || this.question == "";
    },
  },

  methods: {
    AddFaq() {
      if (
        Object.keys(this.selectedTheme).length > 0 &&
        this.selectedTheme.constructor === Object &&
        this.question
      ) {
        const data = {
          theme: this.selectedTheme.titre,
          ami: this.selectedAmi,
          question: this.question,
        };

        axios
          .post("/api/new_faq", data)
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.$notify({
              group: "user",
              title: "Question envoyée avec succès",
              type: "success",
            });
            this.$router.push({ name: "faq" });
            location.reload();
          })
          // eslint-disable-next-line no-unused-vars
          .catch((response) => {
            this.$notify({
              group: "user",
              title: "Erreur lors d'envoi de la question",
              type: "error",
            });
          });
      }
    },

    annuler() {
      this.$router.push({ name: "faq" });
      location.reload();
    },
  },

  watch: {
    selectedTheme(theme) {
      axios.get("/api/" + theme.id + "/amis").then((response) => {
        this.availableAmis = response.data;
      });
    },
  },

  created() {
    axios.get("/api/themes/").then((response) => {
      this.availableThemes = response.data;
    });
  },
};
</script>
