<template>
  <div>
    <button class="btn btn-outline-info mb-4" @click="revenir">
      Revenir à la liste
    </button>

    <form
      action=""
      method="POST"
      enctype="multipart/form-data"
      class="ui large form"
    >
      <b-form-group label="Type d'AMI:">
        <b-form-radio-group
          v-model="selected_ami_type"
          :options="ami_types"
          buttons
          button-variant="outline-primary"
          size="md"
        />
      </b-form-group>

      <b-form-group label="Titre:">
        <b-form-input
          v-model="titre"
          :state="titre.length > 0"
          type="text"
          placeholder="Titre"
        />
      </b-form-group>

      <b-form-group label="Nom:">
        <b-form-input
          v-model="name"
          :state="name.length > 0"
          type="text"
          placeholder="name"
        />
      </b-form-group>

      <b-form-group label="Theme:">
        <b-form-select
          v-model="theme"
          :state="theme.length > 0"
          :options="themes_options"
        />
      </b-form-group>

      <b-form-group label="Date et heure d'ouverture:">
        <b-row>
          <b-col>
            <b-form-datepicker
              v-model="open_date"
              :state="Boolean(open_date)"
              placeholder="Date d'ouverture"
            />
          </b-col>
          <b-col>
            <b-form-timepicker
              v-model="open_time"
              :state="Boolean(open_time)"
              placeholder="Heure d'ouverture"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Date et heure de cloture:">
        <b-row>
          <b-col>
            <b-form-datepicker
              v-model="close_date"
              :state="Boolean(close_date)"
              placeholder="Date de cloture"
            />
          </b-col>
          <b-col>
            <b-form-timepicker
              v-model="close_time"
              :state="Boolean(close_time)"
              placeholder="Heure de cloture"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <br />

      <b-form-group label="Resumé:">
        <vue-editor id="resume" v-model="resume" />
      </b-form-group>

      <br />

      <div v-if="selected_ami_type == 'interne' || !selected_ami_type">
        <b-form-group label="Présentation détaillée:">
          <b-form-file
            v-model="presentation"
            :state="Boolean(id) || Boolean(presentation)"
            accept="application/pdf,application/zip,application/x-zip-compressed"
          />
          <a v-if="id" :href="id + '/download/presentation'">
            <span>Voir votre fichier</span></a
          >
        </b-form-group>

        <b-form-group label="Template / dossier de candidature:">
          <b-form-file
            v-model="template_candidature"
            :state="Boolean(id) || Boolean(template_candidature)"
            accept="application/msword,application/zip,application/x-zip-compressed"
          />
          <a v-if="id" :href="id + '/download/template_candidature'">
            <span>Voir votre fichier</span></a
          >
        </b-form-group>

        <b-form-group label="NDA:">
          <b-form-file
            v-model="nda"
            :state="Boolean(id) || Boolean(nda)"
            accept="application/msword,application/zip,application/x-zip-compressed"
          />
          <a v-if="id" :href="id + '/download/nda'">
            <span>Voir votre fichier</span></a
          >
        </b-form-group>

        <b-form-group label="Annexe:">
          <b-form-file
            v-model="annexe"
            :state="Boolean(id) || Boolean(nda)"
            accept="application/msword,application/excel,application/zip,application/x-zip-compressed"
          />
          <a v-if="id" :href="id + '/download/annexe'">
            <span>Voir votre fichier</span></a
          >
        </b-form-group>
      </div>

      <div v-else>
        <b-row sm="3">
          <label for="link">Lien:</label>
        </b-row>
        <b-row sm="9">
          <b-form-input
            :state="link.length > 0"
            type="url"
            placeholder="Lien"
            v-model="link"
            id="link"
          />
        </b-row>
      </div>

      <p class="text-warning" v-if="!isValid()">
        Merci de compléter tout le formulaire.
      </p>

      <b-button
        v-if="show_loading_btn"
        style="width: 25%; margin: 1% 0% 4% 35%"
        variant="primary"
        disabled
      >
        <b-spinner small></b-spinner>
        Chargement...
      </b-button>

      <b-button
        v-else
        :disabled="!isValid()"
        @click="AjouterModifierAmi"
        style="width: 25%; margin: 1% 0% 4% 35%"
        variant="primary"
      >
        <span v-if="id"><b>Modifier</b></span>
        <span v-else><b>Ajouter</b></span>
      </b-button>
    </form>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  props: {
    selectedAmi: Object,
  },

  components: {
    VueEditor,
  },

  data() {
    return {
      show_loading_btn: false,
      id: "",
      selected_ami_type: "interne",
      name: "",
      titre: "",
      resume: "",
      //
      open_date: "",
      open_time: "",
      close_date: "",
      close_time: "",
      //
      theme: "",
      link: "",
      // Files
      presentation: null,
      template_candidature: null,
      nda: null,
      annexe: null,
      // Options
      ami_types: [
        { text: "Interne", value: "interne" },
        { text: "Externe", value: "externe" },
      ],
      themes_options: [],
    };
  },

  methods: {
    isValid() {
      return this.titre.length > 0 && this.name.length > 0;
    },

    revenir() {
      this.$router.push("/admin/amis/");
    },

    AjouterModifierAmi() {
      this.show_loading_btn = true;
      const formData = new FormData();

      if (this.id) {
        formData.append("id", this.id);
      }

      formData.append("name", this.name);
      formData.append("titre", this.titre);

      formData.append("open_date", `${this.open_date} ${this.open_time}`);
      formData.append("close_date", `${this.close_date} ${this.close_time}`);

      formData.append("resume", this.resume);
      formData.append("theme", this.theme);
      formData.append("ami_type", this.selected_ami_type);
      formData.append("link", this.link);

      const addFile = (key) => {
        const file = this[key];
        if (file) {
          formData.append(key, file);
        }
      };

      if (this.selected_ami_type == "interne") {
        addFile("template_candidature");
        addFile("presentation");
        addFile("nda");
        addFile("annexe");
      }

      const vue = this;
      axios
        .post("/admin/api/amis/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          vue.$notify({
            group: "user",
            title: "Ajouté ou modifié avec succès",
            type: "success",
          });
          vue.$router.push("/admin/amis/");
        })
        .catch(function () {
          vue.show_loading_btn = false;
          vue.$notify({
            group: "user",
            title:
              "Oups, une erreur est survenue, veuillez réessayer ultérieurement",
            type: "error",
          });
        });
    },
  },

  created() {
    axios.get("/api/themes/").then((response) => {
      for (const i in response.data) {
        this.themes_options.push(response.data[i].name);
      }
    });

    if (this.selectedAmi && Object.keys(this.selectedAmi).length > 0) {
      this.id = this.selectedAmi.id;
      this.name = this.selectedAmi.name;
      this.titre = this.selectedAmi.titre;
      this.resume = this.selectedAmi.resume;

      this.open_date = this.selectedAmi.open_date.split(" ")[0];
      this.open_time = this.selectedAmi.open_date.split(" ")[1];
      this.close_date = this.selectedAmi.close_date.split(" ")[0];
      this.close_time = this.selectedAmi.close_date.split(" ")[1];

      this.theme = this.selectedAmi.theme;
      this.link = this.selectedAmi.link;
      if (this.selectedAmi.ami_type) {
        this.selected_ami_type = this.selectedAmi.ami_type;
      } else {
        this.selected_ami_type = "interne";
      }
    }
    this.$emit("selectedAmi", {});
  },
};
</script>
