<template>
  <div style="margin: 7% 5%">
    <div>
      <response-form
        v-if="!afficherDetailCandidature"
        @afficherDetailCandidature="afficherDetailCandidature = $event"
        @amiResponse="amiResponse = $event"
        @ToValidate="ToValidate = $event"
      ></response-form>

      <detail-reponse
        v-if="afficherDetailCandidature"
        :amiResponse="amiResponse"
        @afficherDetailCandidature="afficherDetailCandidature = $event"
        :ToValidate="ToValidate"
      ></detail-reponse>
    </div>

    <notifications style="height: 60px" group="user" position="top center" />
  </div>
</template>

<script>
import axios from "axios";

import ResponseForm from "./ResponseForm";
import DetailReponse from "./DetailReponse";

export default {
  data: function () {
    return {
      afficherDetailCandidature: false,
      ToValidate: false,
      // amiResponse: {
      //   "reponse_type": "individuelle",
      // },

      amiResponse: {},
      general_conditions: [],
      proposition_du_candidat: [],
    };
  },

  methods: {
    Firstsave(ami) {
      if (!("submitted" in ami)) {
        ami.submitted = false;
      }
      if (this.selected_reponse_type == "consortium") {
        ami.partenaires = this.partenaires;
      }
      ami.reponse_type = this.selected_reponse_type;
      axios.post("/api/submit_reponse", ami);
    },
  },

  components: {
    ResponseForm,
    DetailReponse,
  },

  created() {
    const ami_id =
      this.$route.path.split("/")[this.$route.path.split("/").length - 2];

    axios.get("/api/reponse_saved_on_db/" + ami_id).then((response) => {
      if (response.data == false) {
        this.Firstsave(this.amiResponse);
      }
    });

    if (parseInt(ami_id)) {
      axios.get("/api/get_reponse/" + ami_id).then((response) => {
        this.amiResponse = response.data;
        if (response.data.proposition_du_candidat) {
          this.proposition_du_candidat = ["Telecharger votre fichier"];
        }
      });
    }

    if (this.$route.query.form_type == "detail") {
      this.afficherDetailCandidature = true;
      this.ToValidate = false;
    }
  },
};
</script>
