<template>
  <div style="width: 87%">
    <button class="btn btn-outline-info" @click="revenir">
      Revenir à la liste
    </button>

    <div class="form-group row">
      <label for="name" class="col-sm-2 col-form-label"><b>Nom: </b></label>
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="name"
          :value="selectedUser.nom"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="prenom" class="col-sm-2 col-form-label"
        ><b>Prenom: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="prenom"
          :value="selectedUser.prenom"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="organisme" class="col-sm-2 col-form-label"
        ><b>Organisme: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="organisme"
          :value="selectedUser.organisme"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="siret" class="col-sm-2 col-form-label"><b>Siret: </b></label>
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="siret"
          :value="selectedUser.siret"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="telephone" class="col-sm-2 col-form-label"
        ><b>Telephone: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="telephone"
          :value="selectedUser.telephone"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="Adresse" class="col-sm-2 col-form-label"
        ><b>Adresse: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="Adresse"
          :value="
            selectedUser.rue +
            ' ' +
            selectedUser.ville +
            ' ' +
            selectedUser.code_postal
          "
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="fonction" class="col-sm-2 col-form-label"
        ><b>Fonction: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="fonction"
          :value="selectedUser.fonction"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-sm-2 col-form-label"><b>Email: </b></label>
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="email"
          :value="selectedUser.email"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedUser: Object,
  },

  methods: {
    revenir() {
      this.$router.push("/admin/users/");
    },
  },

  beforeDestroy() {
    this.$emit("selectedUser", {});
  },
};
</script>
