<template>
  <div class="mt-4">
    <a-table
      :columns="columns"
      :dataSource="items"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="record">
        <a :href="record.id">Edit</a>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  data() {
    // eslint-disable-next-line no-undef
    const data = DATA;

    const columns = [
      {
        title: "Titre",
        dataIndex: "title",
        sorter: true,
        width: "50%",
      },
      {
        title: "Date publication",
        dataIndex: "date",
        sorter: true,
        width: "15%",
      },
      {
        title: "Auteur",
        dataIndex: "author",
        sorter: true,
        width: "15%",
      },
      {
        title: "Action",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: "10%",
      },
    ];
    return {
      columns: columns,
      items: data.posts,
    };
  },
};
</script>
