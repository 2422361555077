<template>
  <div style="width: 87%">
    <button class="btn btn-outline-info" @click="revenir">
      Revenir à la liste
    </button>

    <h1 style="margin: 2% 20%; width: 80%">{{ selectedTheme.titre }}</h1>

    <div class="form-group row">
      <label for="name" class="col-sm-2 col-form-label"><b>Nom: </b></label>
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          type="text"
          readonly
          class="form-control-plaintext"
          id="name"
          :value="selectedTheme.nom"
        />
      </div>
    </div>

    <div class="form-group">
      <label for="description" class="col-form-label"
        ><b>Description: </b></label
      >
      <div
        style="background-color: rgba(216, 216, 226, 0.27); padding: 5px"
        id="description"
        v-html="selectedTheme.description"
      ></div>
    </div>

    <div class="form-group">
      <label for="tutorial" class="col-form-label"><b>Tutorial: </b></label>
      <div
        style="background-color: rgba(216, 216, 226, 0.27); padding: 5px"
        id="tutorial"
        v-html="selectedTheme.tutorial"
      ></div>
    </div>

    <div class="form-group">
      <label for="charte" class="col-form-label"><b>Charte: </b></label>
      <div
        style="background-color: rgba(216, 216, 226, 0.27); padding: 5px"
        id="charte"
        v-html="selectedTheme.charte"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedTheme: Object,
  },
  methods: {
    revenir() {
      this.$router.push("/admin/themes/");
    },
  },
  beforeDestroy() {
    this.$emit("selectedTheme", {});
  },
};
</script>
