<template>
  <div>
    <!--<button type="button" class="mb-3" @click="$emit('faqPage', 'add')">-->
    <!--<i style="font-size: 24px;" class="fa">&#xf067;</i>-->
    <!--</button>-->

    <b-dropdown variant="link" size="lg" no-caret>
      <template slot="button-content"
        ><i style="font-size: 24px" class="fa">&#xf067;</i
        ><span class="sr-only">New</span></template
      >

      <b-dropdown-item @click="addFaq">Remplir manuellement</b-dropdown-item>
      <b-dropdown-item @click="uploadFile = true"
        >A partir d'un fichier excel</b-dropdown-item
      >
    </b-dropdown>

    <b-col sm="9" v-if="uploadFile">
      <file-pond
        style="margin: 4% 0% 4% 12%"
        id="file-upload2"
        ref="pond"
        label-idle="Déposez le fichier ici..."
        :acceptedFileTypes="[
          'application/wps-office.xlsx',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]"
        server="/admin/api/uploadFaqFile"
        @processfile="refresh()"
      />
    </b-col>

    <div style="margin-top: 10px">
      <h5 v-if="faqNonRepondu.length > 0" class="font-weight-bold">
        Questions sans réponse
      </h5>

      <table class="table table-bordered table-striped">
        <tr :key="'faq' + index" v-for="(faq, index) in faqNonRepondu">
          <td style="width: 70%">
            <a @click="detail(faq)">
              {{ faq.question }}
            </a>
          </td>
          <td>
            {{ faq.when }}
          </td>
          <td>
            <button
              @click="modify(faq)"
              type="button"
              class="close"
              aria-label="Close"
            >
              <i style="font-size: 24px" class="fa">&#xf044;</i>
            </button>
          </td>
          <td>
            <button
              @click="remove(faq)"
              type="button"
              class="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </td>
        </tr>
      </table>

      <br />

      <h5 v-if="faqRepondu.length > 0" class="font-weight-bold">
        Questions avec réponse
      </h5>

      <table style="width: 100%" class="table table-bordered table-striped">
        <tr :key="'faq' + index" v-for="(faq, index) in faqRepondu">
          <td style="width: 70%">
            <a @click="detail(faq)">
              {{ faq.question }}
            </a>
          </td>

          <td>
            {{ faq.when }}
          </td>
          <td>
            <button
              @click="modify(faq)"
              type="button"
              class="close"
              aria-label="Close"
            >
              <i style="font-size: 24px" class="fa">&#xf044;</i>
            </button>
          </td>
          <td>
            <button
              @click="remove(faq)"
              type="button"
              class="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import axios from "axios";
import fp from "lodash/fp";

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  data() {
    return {
      uploadFile: false,
      faqs: [],
      faqNonRepondu: [],
      faqRepondu: [],
    };
  },

  components: {
    FilePond,
  },

  created() {
    this.getAllFaqs();
  },

  methods: {
    getAllFaqs() {
      axios.get("/api/all_faqs").then((response) => {
        this.faqs = response.data;
        this.faqRepondu = fp.filter((x) => x.reponse)(this.faqs);
        this.faqNonRepondu = fp.filter((x) => !x.reponse)(this.faqs);
      });
    },

    addFaq() {
      this.$router.push("/admin/faqs/add");
    },

    refresh() {
      this.getAllFaqs();
      this.uploadFile = false;
    },

    modify(faq) {
      this.$emit("selectedFaq", faq);
      this.$router.push("/admin/faqs/modify");
    },

    detail(faq) {
      this.$emit("selectedFaq", faq);
      this.$router.push("/admin/faqs/detail");
    },

    // detectorFunction:(source, type) => {
    //   return new Promise((resolve, reject) => {
    //       // do custom type detection here and return with promise
    //       let validTypes = [
    //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //         "application/vnd.ms-excel",
    //         "application/vnd.oasis.opendocument.spreadsheet"
    //       ]
    //
    //
    //       // Check of Valid MIME type
    //       let bCondition01 = (validTypes.indexOf(type) > -1)
    //
    //       // In case MIME-Type is empty ... check for extention
    //       let ext = source.name.slice(-5).toLowerCase()
    //       let bCondition02 = (type == "") && (ext === ".xlsx")
    //
    //       if (bCondition01 || bCondition02) {
    //         resolve(validTypes[0])
    //       } else {
    //         reject()
    //       }
    //
    //       // resolve(type);
    //     })
    // }, // End of detectorFunction

    remove(faq) {
      const vue = this;
      this.$dlg.alert(
        "Êtes-vous sûr de vouloir supprimer " + faq.question + " ?",
        function () {
          axios
            .post("/admin/api/delete_faq", faq)
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              vue.$notify({
                group: "user",
                title: "Supprimé avec succès",
                type: "success",
              });
              this.getAllFaqs();
            })
            // eslint-disable-next-line no-unused-vars
            .catch((response) => {
              vue.$notify({
                group: "user",
                title: "Erreur de suppression",
                type: "error",
              });
              this.getAllFaqs();
            });
        },
        {
          messageType: "confirm",
          language: "en",
        }
      );
    },
  },
};
</script>
