<template>
  <div>
    <button class="btn btn-outline-info" @click="revenir">
      Revenir à la liste
    </button>

    <div class="form-group row">
      <label for="theme" class="col-sm-2 col-form-label"
        ><b>Thématique: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          :value="selectedFaq.theme.titre"
          type="text"
          readonly
          class="form-control-plaintext"
          id="theme"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="ami" class="col-sm-2 col-form-label"><b>Ami: </b></label>
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          :value="selectedFaq.ami.titre"
          type="text"
          readonly
          class="form-control-plaintext"
          id="ami"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="date" class="col-sm-2 col-form-label"
        ><b>Date création: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <input
          :value="selectedFaq.when"
          type="text"
          readonly
          class="form-control-plaintext"
          id="date"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="question" class="col-sm-2 col-form-label"
        ><b>Question: </b></label
      >
      <div style="padding-left: 8%" class="col-sm-8">
        <div
          style="background-color: rgba(216, 216, 226, 0.27); padding: 5px"
          id="question"
          v-html="selectedFaq.question"
        ></div>
      </div>
    </div>

    <div class="form-group">
      <label for="reponse" class="col-form-label"><b>Reponse: </b></label>
      <div
        style="background-color: rgba(216, 216, 226, 0.27); padding: 5px"
        id="reponse"
        v-html="selectedFaq.reponse"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedFaq: Object,
  },

  methods: {
    revenir() {
      this.$router.push("/admin/faqs/");
    },
  },

  beforeDestroy() {
    this.$emit("selectedFaq", {});
  },
};
</script>
