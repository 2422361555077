<template>
  <div>
    <button class="btn btn-outline-info" @click="back">
      Revenir à la liste
    </button>

    <div style="border: 1px solid #ddd" class="mt-3 mb-3 p-2">
      <div style="margin-top: 1%">
        <b-container fluid>
          <b-row sm="3">
            <label for="theme">Thématique:</label>
          </b-row>

          <b-form-select
            id="theme"
            v-model="selectedTheme"
            :options="themeOptions"
            class="mb-3"
          >
          </b-form-select>

          <b-row sm="3">
            <label for="ami">Ami:</label>
          </b-row>

          <b-form-select
            id="ami"
            v-model="selectedAmi"
            :options="amiOptions"
            class="mb-3"
          >
          </b-form-select>

          <b-row sm="3">
            <label for="question">Question:</label>
          </b-row>

          <b-row sm="9">
            <b-form-input
              :state="question.length > 0"
              type="text"
              v-model="question"
              id="question"
            />
          </b-row>

          <br />
          <b-row sm="3">
            <label for="reponse">Réponse:</label>
          </b-row>
          <b-row sm="9">
            <vue-editor id="reponse" v-model="reponse" />
          </b-row>
        </b-container>

        <br /><br /><br />

        <table style="width: 21%">
          <tr>
            <td>Publiée ?</td>

            <td>
              <b-form-checkbox
                id="published"
                switch
                v-model="published"
                name="check-button"
              >
              </b-form-checkbox>
            </td>
          </tr>
        </table>

        <br />
        <b-button
          @click="postFaq"
          style="width: 25%; margin: 1% 0% 4% 35%"
          variant="primary"
        >
          <!--<span v-if="id && question"><b>Répondre</b></span>-->
          <!--<span v-else-if="id"><b>Modifier</b></span>-->
          <span v-if="id"><b>Modifier</b></span>
          <span v-else><b>Ajouter</b></span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import fp from "lodash/fp";

export default {
  props: {
    selectedFaq: Object,
  },

  components: {
    VueEditor,
  },

  data() {
    return {
      id: "",
      question: "",
      reponse: "",
      published: false,
      //
      selectedAmi: null,
      selectedTheme: null,
      amiOptions: [],
      themeOptions: [],
    };
  },

  created() {
    if (this.selectedFaq && Object.keys(this.selectedFaq).length > 0) {
      this.id = this.selectedFaq.id;
      this.question = this.selectedFaq.question;
      this.reponse = this.selectedFaq.reponse;
      this.published = this.selectedFaq.published;
      this.selectedTheme = this.selectedFaq.theme.id;
      this.selectedAmi = this.selectedFaq.ami.id;
    }

    this.updateOptions();

    this.$emit("selectedFaq", {});
  },

  watch: {
    selectedTheme() {
      this.updateOptions();
    },
  },

  methods: {
    back() {
      this.$router.push("/admin/faqs/");
    },

    updateOptions() {
      let url = "";

      if (this.selectedTheme) {
        url = `/api/${this.selectedTheme}/amis`;
      } else {
        url = "/api/amis";
      }

      axios.get(url).then((response) => {
        this.amiOptions = fp.map((x) => ({ value: x.id, text: x.titre }))(
          response.data
        );
      });

      axios.get("/api/themes/").then((response) => {
        this.themeOptions = fp.map((x) => ({ value: x.id, text: x.titre }))(
          response.data
        );
      });
    },

    postFaq() {
      const response = {
        question: this.question,
        reponse: this.reponse,
        published: this.published,
        theme_id: this.selectedTheme,
        ami_id: this.selectedAmi,
      };
      if (this.id) {
        response.id = this.id;
      }
      const vue = this;
      axios
        .post("/admin/api/add_or_update_faq", response)
        .then((response) => {
          if (response.data.success) {
            vue.$notify({
              group: "user",
              title: "Ajouté avec succès",
              type: "success",
            });
            this.$router.push("/admin/faqs/");
          } else {
            vue.$notify({
              group: "user",
              title: "Erreur de sauvegarde (" + response.data.message + ")",
              type: "error",
            });
          }
        })
        .catch(function () {
          vue.$notify({
            group: "user",
            title:
              "Ooops, une erreur est survenue, veuillez réessayer ulterieurement",
            type: "error",
          });
        });
    },
  },
};
</script>
