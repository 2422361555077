<template>
  <div>
    <all-ami
      @selectedAmi="selectedAmi = $event"
      v-if="$route.path == '/admin/amis/'"
      @AmiPage="AmiPage = $event"
    ></all-ami>
    <create-modify-ami
      style="width: 88%"
      :selectedAmi="selectedAmi"
      @selectedAmi="selectedAmi = $event"
      v-if="
        $route.path == '/admin/amis/add' ||
        ($route.path == '/admin/amis/modify' && selectedAmi != {})
      "
      @AmiPage="AmiPage = $event"
    ></create-modify-ami>
    <detail
      :selectedAmi="selectedAmi"
      @selectedAmi="selectedAmi = $event"
      v-if="
        $route.path == '/admin/amis/detail' ||
        ($route.path == '/admin/amis/detail' && selectedAmi != {})
      "
      @AmiPage="AmiPage = $event"
    ></detail>
    <notifications style="height: 60px" group="user" position="top center" />
  </div>
</template>

<script>
import CreateModifyAmi from "./CreateModifyAmi";
import AllAmi from "./AllAmis";
import detail from "./detail";

export default {
  data() {
    return {
      selectedAmi: {},
      AmiPage: "list",
    };
  },
  components: {
    CreateModifyAmi,
    AllAmi,
    detail,
  },
};
</script>
