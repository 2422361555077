<template>
  <span>
    <button @click="deletePJ" class="btn btn-danger ml-5">
      Supprimer les PJ des réponses aux AMI terminés
    </button>
    <notifications style="height: 60px" group="user" position="top center" />
  </span>
</template>

<script>
import axios from "axios";

export default {
  name: "ButtonDeletePJ",
  methods: {
    deletePJ() {
      var vue = this;
      this.$dlg.alert(
        "Êtes-vous sûr de vouloir supprimer toutes les pièces jointes des réponses aux AMI terminés ?",
        function () {
          axios
            .post("/admin/api/delete_pj_ami_finish")
            .then(() => {
              vue.$notify({
                group: "user",
                title: "PJ supprimées avec succès",
                type: "success",
              });
            })
            .catch(function () {
              vue.$notify({
                group: "user",
                title:
                  "Ooops, une erreur est survenue, veuillez réessayer ulterieurement",
                type: "error",
              });
            });
        },
        {
          messageType: "confirm",
          language: "en",
        }
      );
    },
  },
};
</script>
