<template>
  <div>
    <h3 style="margin-bottom: 2em" class="text-center">
      Votre réponse à:
      <b style="margin-left: 1%">{{ amiResponse.titre_ami }}</b>
    </h3>

    <div>
      <table
        style="
          width: 56%;
          border-collapse: separate;
          border-spacing: 2em;
          margin: 0 0 1% 23%;
        "
      >
        <tr>
          <td>
            <b>Organisation</b>
          </td>

          <td>
            {{ amiResponse.candidate }}
          </td>
        </tr>

        <tr>
          <td>
            <b>Fonction</b>
          </td>

          <td>
            {{ amiResponse.fonction }}
          </td>
        </tr>

        <tr>
          <td>
            <b>Rue</b>
          </td>

          <td>
            {{ amiResponse.rue }}
          </td>
        </tr>

        <tr>
          <td>
            <b>Ville</b>
          </td>

          <td>
            {{ amiResponse.ville }}
          </td>
        </tr>

        <tr>
          <td>
            <b>Code postal</b>
          </td>

          <td>
            {{ amiResponse.cp }}
          </td>
        </tr>

        <tr>
          <td>
            <b>Siret / ID Laboratoire</b>
          </td>

          <td>
            {{ amiResponse.siret }}
          </td>
        </tr>

        <tr>
          <td>
            <b>E-mail</b>
          </td>

          <td>
            {{ amiResponse.mail }}
          </td>
        </tr>

        <tr>
          <td>
            <b>Téléphone</b>
          </td>

          <td>
            {{ amiResponse.tel }}
          </td>
        </tr>

        <tr v-if="amiResponse.partners && amiResponse.partners.length > 0">
          <td>
            <b>Partenaires</b>
          </td>
          <td>
            <ul>
              <li
                :key="'partner' + index"
                v-for="(partenaire, index) in amiResponse.partners"
              >
                {{ partenaire.organisation }}
              </li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <b>Fichier proposition du candidat</b>
          </td>

          <td>
            <a href="download/proposition_du_candidat">Voir le fichier</a>
          </td>
        </tr>
      </table>

      <div style="margin: 0% 40%; width: 50%">
        <div v-if="ToValidate">
          <b-button
            class="btn btn-info"
            @click="$emit('afficherDetailCandidature', false)"
            >Revenir
          </b-button>

          &nbsp;&nbsp;
          <!--style="width: 25%"-->
          <b-button @click="soumettreCandidature" variant="primary">
            <b>Soumettre</b>
          </b-button>
        </div>

        <div v-else>
          <b-button class="btn btn-info" @click="revenir">Revenir</b-button>
          &nbsp;&nbsp;
          <!--style="width: 25%"-->
          <b-button @click="modifierCandidature" variant="primary">
            <b>Modifier</b>
          </b-button>
        </div>
      </div>

      <!-- TODO -->
      <!--<div style="margin: 0 0 5% 10%">-->
      <!--<b-button-->
      <!--style="width: 20%;  margin-left: 15%"-->
      <!--variant="outline-success"-->
      <!--&gt;Exporter en pdf-->
      <!--</b-button>-->
      <!--<b-button-->
      <!--style="width: 20%; margin-left: 15%"-->
      <!--variant="outline-danger"-->
      <!--&gt;Supprimer-->
      <!--</b-button>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    amiResponse: Object,
    ToValidate: Boolean,
  },

  methods: {
    modifierCandidature() {
      this.$emit("ToValidate", true);
      this.$emit("afficherDetailCandidature", false);
    },

    revenir() {
      this.$router.push({ name: "mes_applications" });
      location.reload();
    },

    soumettreCandidature() {
      const vue = this;
      const response = this.amiResponse;
      response.submitted = true;
      response.send_confirmation_mail = true;
      axios
        .post("/api/submit_reponse", response)
        .then(() => {
          // force change url
          this.$router.push({ name: "application_success" });
          location.reload();
        })
        .catch(function () {
          vue.$notify({
            group: "user",
            title:
              "Ooops, une erreur est survenue, veuillez réessayer ulterieurement",
            type: "error",
          });
        });
    },
  },
};
</script>
